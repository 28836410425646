<template>
  <ul>
    <li>Go (Kevin + Guillaume)</li>
    <li>Node JS (Yanis)</li>
    <li>Java (Raph)</li>
    <li>Python (Raph)</li>
    <li>.Net (Guillaume)</li>
    <li>PHP (Merveille)</li>
  </ul>
</template>

<script>
export default {};
</script>

<style></style>
